import React from "react";
import CoursesCarouselJson from "../Commons/CoursesCarouselJson";
import BreadCrumbJson from "../Commons/BreadCrumbJson";
import FAQJson from "../Commons/FAQJson";
import CommonSeoSchema from "../Commons/CommonSeoSchema";

const CoursesListSeo = ({ data, metaData }) => {
  const SeoData = {
    ...metaData,
    title:
      metaData?.title ||
      metaData?.haloSectionData?.heading ||
      "Game Development Courses at Outscal",
    description:
      metaData?.description ||
      metaData?.haloSectionData?.subHeading ||
      "Upskill with Outscal’s flagship game development programs and work on your favorite games with studios like Ubisoft, EA, Rockstar etc",
    keywords:
      metaData?.keywords ||
      "game development courses, game programming classes, video game design training, game design and development courses, game development tutorials, learn game development online, game development certification, game design workshops, Unity game development courses, Unreal Engine tutorials, 2D game development classes, 3D game development classes, mobile game development courses, game art and animation classes, game development bootcamp, online game design programs, interactive storytelling courses, game testing and quality assurance training, multiplayer game development workshops, game audio and sound design courses, virtual reality game development classes.",
    imageUrl:
      metaData?.image?.url ||
      "https://outscal-assets.s3.ap-south-1.amazonaws.com/SEO/courses-list-halosection.png",
    imageType: "image/png",
    url: metaData.url,
    imageAlt: metaData?.image?.alternativeText || "Courses At Outscal",
  };

  return (
    <>
      <CommonSeoSchema {...SeoData} />
      <FAQJson data={data?.faqs} />
      <CoursesCarouselJson data={data?.coursesList} />
      <BreadCrumbJson items={metaData?.breadcrumbs} />
    </>
  );
};

export default CoursesListSeo;
