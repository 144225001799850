import { ColDiv } from "@/outscal-commons-frontend/Styled/LayoutUtils";
import React from "react";
import styled from "styled-components";
import PageRenderer from "../PageRenderer.service";
import Constant from "@/staticData/constant";

const CoursesPageView = ({ components, isLoggedIn }) => {
  return <PageRenderer components={components} />;
};

export default CoursesPageView;
