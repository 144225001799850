import Head from "next/head";

const CoursesCarouselJson = ({ data }) => {
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "ItemList",
    itemListElement: data.map((course, index) => ({
      "@type": "Course",
      position: index + 1,
      name: course.title,
      description: course.description,
      url: `/course/${course.slug}`,
      provider: {
        "@type": "Organization",
        name: "Home",
        url: "www.outscal.com",
      },
      offers: [
        {
          "@type": "Offer",
          category: course.free ? "Free" : "Paid",
          priceCurrency: "INR",
        },
      ],
      about: course?.skills?.data?.map(item => item?.display_name) || [],
      hasCourseInstance: [
        {
          // 100hrs per month for 4 months (paid) | 20hrs per week for 1 week (free)
          courseSchedule: {
            "@type": "Schedule",
            duration: course.free ? "PT20H" : "PT100H",
            repeatCount: course.free ? 1 : 4,
            repeatFrequency: course.free ? "Weekly" : "Monthly",
          },
          "@type": "CourseInstance",
          courseMode: "Online",
        },
      ],
    })),
  };

  return (
    <Head>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(structuredData) }}
      />
    </Head>
  );
};

export default CoursesCarouselJson;
