import BasePageDataController from "../../BasePageData.controller";

class CoursesPageDataController extends BasePageDataController {
  constructor() {
    super();
  }

  #getCoursesListDataFromPageData(coursesPageData) {
    const sections = coursesPageData?.sections || [];
    const courseASection = sections.filter(
      (section) => section.__component === "section.course-a"
    );
    return courseASection.length > 0 ? courseASection[0]?.listData : [];
  }

  getCustomPageData(url, pageData) {
    try {
      const coursesList = this.#getCoursesListDataFromPageData(pageData);
      const paidCoursesIdAndLabels = coursesList?.map((course) => ({
        id: course?.slug,
        label: course?.title,
      }));
      return { coursesList, paidCoursesIdAndLabels };
    } catch (error) {
      console.error("Error returning custom page data:", error);
      return null;
    }
  }

  async getPageData(locale, defaultLocale) {
    let pageData = await super.getPageData(
      this.url,
      "&populate[sections][populate][listData][populate][image][fields][0]=url&populate[sections][populate][listData][populate][skills][fields][0]=display_name&populate[sections][populate][listData][populate]=*&populate[sections][populate][bgImage][fields][0]=url",
      locale,
      defaultLocale
    );
    return pageData;
  }
}

export default new CoursesPageDataController();
