import React from "react";
import CoursesPageController from "@/mvcComponents/Pages/CoursesPage/CoursesPage.Controller";
import CoursesPageDataController from "@/Logic/ServerLogic/PageDataControllers/Courses/CoursesPageData.controller";
CoursesPageDataController.initialize("/courses");

const CoursesLandingPage = ({ pageData, customPageData }) => {
  return (
    <div className="courses-page">
      <CoursesPageController
        pageData={pageData}
        customPageData={customPageData}
      />
    </div>
  );
};

export const getStaticProps = async ({ params, locale, defaultLocale }) => {
  try {
    const coursesPageData = await CoursesPageDataController.getPageData(
      locale,
      defaultLocale
    );
    let {
      pageData = {},
      customPageData = {},
      alternateLanguageData = {},
    } = coursesPageData || {};

    const { languageAlternates = {}, canonicalUrl = "" } =
      alternateLanguageData || {};

      let isRedirection = CoursesPageDataController.checkRedirection(pageData);
      if (isRedirection) {
        return isRedirection;
      }

    return {
      props: {
        pageData: {
          ...pageData,
          seo: {
            ...pageData?.seo,
            url: canonicalUrl,
            languageAlternates,
          },
        },
        customPageData,
      },
      revalidate: 60,
    };
  } catch (err) {
    console.log("ERROR", err);
    return {
      props: {
        pageData: {},
        customPageData: {},
      },
    };
  }
};

export default CoursesLandingPage;
