import { BreadcrumbJsonLd } from "next-seo";
import React from "react";

const BreadCrumbJson = ({
  items = [
    {
      position: 1,
      name: "home",
      item: "https://outscal.com/",
    },
  ],
}) => {
  return <BreadcrumbJsonLd itemListElements={items} />;
};

export default BreadCrumbJson;
